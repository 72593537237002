<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="quota">
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>

      <el-date-picker
        v-model="date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        :clearable="false"
      >
      </el-date-picker>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column label="序号" type="index" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transferRealname" label="用户姓名" width="130">
        </el-table-column>
        <el-table-column prop="transferPhone" label="手机号" width="150">
        </el-table-column>
        <el-table-column prop="transferTime" label="转账时间">
        </el-table-column>
        <el-table-column prop="transferNum" label="转出数量"> </el-table-column>
        <el-table-column prop="payeePhone" label="收款账户"> </el-table-column>
        <el-table-column prop="payeeName" label="收款人姓名"> </el-table-column>
        <el-table-column prop="status" label="状态" width="140" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                @click="handleClick(scope.row, 1)"
                type="text"
                size="small"
                >通过</el-button
              >
              <el-button
                @click="handleClick(scope.row, 2)"
                type="text"
                size="small"
                >不通过</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import "./index.scss";
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      phone: "",
      date: "",
      tableData: [],
      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "转出额度赠送审核",
        },
        {
          name: "待审核列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.getOutQuotaList,
      method: "POST",
      params: JSON.stringify({
        endTime: "",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        sign: "",
        startTime: "",
        status: "",
        token: "",
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    // 查询
    searchList() {
      if (this.phone || this.date) {
        showLoading();
        const opt = {
          url: reqUrl.getOutQuotaList,
          method: "POST",
          params: JSON.stringify({
            endTime: this.date[1],
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            sign: "",
            startTime: this.date[0],
            status: "",
            token: "",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 审核通过或不通过
    handleClick(row, index) {
      if (index == 1) {
        this.$confirm("您确定要通过审核吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const opt = {
              url: reqUrl.outQuotaResultUrl,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                id: row.id,
                status: "1",
              }),
              resFunc: (res) => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "审核成功!",
                  });
                  this.refresh();
                }
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审核",
            });
          });
      } else if (index == 2) {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "拒绝原因不得为空",
          inputValidator: (value) => {
            if (!value) {
              return "拒绝原因不得为空";
            }
          },
        })
          .then(({ value }) => {
            const opt = {
              url: reqUrl.outQuotaResultUrl,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                id: row.id,
                status: "2",
                rejectReason: value,
              }),
              resFunc: (res) => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "已拒绝通过",
                  });
                  this.refresh();
                }
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消审核",
            });
          });
      }
    },

    // 分页
    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getOutQuotaList,
        method: "POST",
        params: JSON.stringify({
          endTime: this.date[1],
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          sign: "",
          startTime: this.date[0],
          status: "",
          token: "",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    // 重置
    reset() {
      if (this.phone || this.date) {
        this.phone = "";
        this.date = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>
